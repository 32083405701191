import { WORDS_BASE } from './bycategory/words_base'
import { WORDS_DISCORD } from './bycategory/words_discord'
import { WORDS_TOURNEY } from './bycategory/words_tourney'
import { WORDS_EU } from './bycategory/words_eu'
import { WORDS_OCE } from './bycategory/words_oce'
import { WORDS_ASIA } from './bycategory/words_asia'

var arr = [
  'builtdiff',
  'juliettes',
  'archanist',
  'whiterice',
  'chibitora',
  'shinonome',
  'hyperbeam',
  'shuramoji',
  'gigablast',
  'firewalls',
  'xenocloak',
  'maelstrom',
  'goalgates',
  'fieryfist',
  'crossover',
  'creations',
  'abilities',
  'primaries',
  'secondary',
  'knockback',
  'staggered',
  'explosion',
  'cooldowns',
  'takedowns',
  'trainings',
  'gingerpop',
  'megaverse',
  'srskull45',
  'pissmidas',
  'daftgrass',
  'schneider',
  'shiroaisu',
  'eloheaven',
  'firelight',
  'eradeadge',
  'dububedge',
  'julicomfy',
  'juliheart',
  'julipunch',
  'venomyami',
  'lamacraft',
  'singapore',
  'challonge',
  'countdown',
  'raaaahhhh',
  'airhockey',
  'westerado',
  'kazthe2st',
  'jarthejar',
  'waveearly',
  'krowleone',
  'doubleb03',
  'walnutnut',

  'kraken', // mods
  'yatta',
  'twiglief',
  'hime',
  'rosa',
  'linean',
  'rimuru',
  'start',
  'nocatoca',
  'noca',
  'toca',
  'ether',
  'nillun',
  'nolan',
  'ian',
  'iak',

  'chris', // ody
  'bokehbot',
  'sstrikerr',
  'repertoir',
  'rkrigney',
  'lutzburg',
  'gahzi',
  'maddump',
  'drika',
  'liyart',
  'nosro',
  'tkdd',
  'archod',
  'gustogod',
  'kevin',
  'eric',

  'karmak', // comms
  'beomulf',

  'mnnmouse', // OScord
  'ecastle2',
  'pigzilla',
  'hinki',
  'garfield',
  'markee',
  'nines',
  'fringer',
  'juggiegud',
  'flame',
  'iheartpie',
  'z3sleeper',
  'kazmasky',
  'bounding',
  'gunner',
  'gunner62',
  'timsolo',
  'goku',
  'pixid',
  'happens',
  'hayden',
  'tofu1',
  'litaf',
  'nafan',
  'nafanuwu',

  'kolaishu', // eu
  'nero',
  'kekle9',
  'haashi',
  'nyan',
  'kiddo',
  'trabje',
  'trider',
  'isnack',
  'nitrun',
  'oneka',
  'arkasha',
  'trusty',
  'symphonus',
  'giovanni',
  'tazemany',
  'nycrofear',
  'hikari',
  'vekia',
  'saxoseal',
  'vlaja',
  'daan',
  'mrtorg',
  'torg',
  'vyzixen',
  'teayen',
  'surgetale',
  'deez',
  'fozey',
  'umizoomi',
  'sokks',
  'layfire',
  'trounzey',
  'stimoro',
  'taiganes',
  'zyoszett',
  'jimray3',
  'alicia',
  'midl',
  'vipr3',
  'red',
  'dudi',
  'gonzo',
  'quivscor',
  'nastou',
  'rapunzel',
  'matyous',
  'matyoustv',
  'tempax',
  'vardek',
  'trick69',
  'aimmel',
  'hath',
  'vane',
  'akaryuwu',
  'yonko34',
  'devexia',

  'f4ntasy', // na comp
  'kero',
  'backfired',
  'jmpr',
  'shireee',
  'wizander',
  'bentup',
  'curiacity',
  'blender',
  'yazo',
  'trademark',
  'xeno',
  'ethan',
  'cloh',
  'anime',
  'boba',
  'mosi',
  'muffin',
  'mitski',
  'pandaIII',
  'swaghaver',
  'userwet',
  'umc',
  'appa',
  '3zyangle',
  'kazzed',
  'kazzedit',
  'elo',
  'omegalul',

  'beba', // na discord

  'wisdom', // tourneys
  'koalas',
  'surprise',
  'swiss',
  'stout',
  'boona',

  'voltaic', // skins
  'aurora',
  'xko',
  'arcbeam',
  'arcbeams',
]
arr.push(...WORDS_BASE)
arr.push(...WORDS_DISCORD)
arr.push(...WORDS_TOURNEY)
arr.push(...WORDS_EU)
arr.push(...WORDS_OCE)
arr.push(...WORDS_ASIA)

export const VALID_OMEGA = arr
