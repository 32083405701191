export const WORDS_EU = [
  'xmetix',
  'alex2704',
  'alex',
  'crabb',
  'thecrabb',
  'piols',
  'rekkosed',
  'atsuro',
  'kekle',
  'venom',
  'balu',
  'lama',
  'lynx',
  'connax',
  'kitsune',
  'matu42',
  'buntaoo',
]
