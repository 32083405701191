export const WORDS_OCE = [
  'ahmad',
  'aaiyo',
  'aralia',
  'kruha',
  'lunirs',
  'onie',
  'torrchi',
  'thunder',
  'tsuneko',
  'kevfun',
  'ddp',
  'gyatso',
  'nizcoer',
  'zandy',
  'zinnia',
  'nero',
  'rixy',
  'switch',
]
