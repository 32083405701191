export const WORDS_BASE = [
  'kai',
  'kais',
  'rune',
  'runes',
  'dubu',
  'dubus',
  'juli',
  'julis',
  'juliette',
  'luna',
  'lunas',
  'asher',
  'ashers',
  'aimi',
  'aimis',
  'zentaro',
  'juno',
  'junos',
  'estelle',
  'estelles',
  'atlas',
  'drekar',
  'drekars',

  'gloria',
  'david',
  'shieldz',
  'dan',
  'ron',
  'drex',
  'juliet',
  'astrid',
  'kate',
  'nine',
  'eight',

  'omega',
  'obscura',
  'blobbo',
  'blobbos',
  'ahten',
  'ody',
  'odyssey',
  'coredle',

  'cafe',
  'rose',
  'orbital',
  'gilded',
  'duboo',
  'nurse',

  'wall',
  'circle',
  'thorns',
  'nuke',
  'nukes',
  'nuked',
  'crater',
  'boost',
  'whammy',
  'whammies',
  'missile',
  'missiles',
  'rocket',
  'rockets',
  'banish',
  'banished',
  'barrage',
  'barrages',
  'firewall',
  'expanse',
  'expanses',
  'rollout',
  'flurry',
  'flurries',
  'maximus',

  'narrows',
  'origins',
  'split',
  'funnel',
  'streets',
  'ctl',
  'stinger',
  'adren',
  'eject',
  'acro',
  'creators',
  'durable',
  'large',
  'swagger',
  'stack',
  'stacks',
  'clash',
  'clones',
  'omega',
  'chally',
  'diamond',
  'platinum',
  'plat',
  'gold',
  'silver',
  'bronze',
  'rookie',

  'net',
  'cdr',
  'hit',
  'exp',
  'coc',
  'strike',
  'strikes',
  'striking',
  'orb',
  'orbs',
  'hits',
  'nets',
  'light',
  'medium',
  'heavy',
  'dash',
  'dashes',
  'jump',
  'jumps',
  'stagger',
  'haste',
  'creation',
  'buff',
  'buffs',
  'buffing',
  'heal',
  'heals',
  'healing',
  'ability',
  'primary',
  'special',
  'specials',
  'ranged',
  'melee',
  'damage',
  'debuff',
  'debuffs',
  'power',
  'speed',
  'size',
  'blink',
  'blinks',
  'stun',
  'stuns',
  'immune',
  'elusive',
  'emote',
  'emotes',
  'flex',
  'midfield',
  'forward',
  'forwards',
  'goalie',
  'goalies',
  'cooldown',
  'takedown',
  'assist',
  'assists',
  'core',
  'cores',
  'bumpers',
  'pass',
  'passes',
  'passing',
  'save',
  'saves',
  'goal',
  'goals',
  'overtime',
  'striker',
  'strikers',
  'kill',
  'kills',
  'kick',
  'kicks',
  'rock',
  'rocks',
  'puck',
  'pucks',
  'disc',
  'discs',
  'disk',
  'disks',
  'brawler',
  'brawlers',
  'mirror',
  'mirrors',
  'lag',
  'laggy',
  'alt',
  'alts',
  'int',
  'inter',
]
