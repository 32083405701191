export const WORDS_TOURNEY = [
  'ender',
  'ovilee',
  'kbot',
  'hafu',
  'nibiria',
  'beepo',

  'inhouse',
  'inhouses',
  'pickups',
  'pugs',
  'startgg',
  'grilla',
  'octagon',
  'pingpong',
  'chicago',
  'ohio',
  'sandiego',

  'clg',
  'spin',
  'egl',
  'costco',
  'backroom',
  'arcadian',
  'wcw',
  'strsat',
  'whammy',
  'autumn',
  'pbwm',
  'tuesday',
  'bluelock',
  'vaughn',
  'sayachi',
  'kamidere',
  'brickbat',
  'mystic',
  'coyne',
  'saya',
  'kuyachi',
  'prime',
  'frust',

  'cbk',
  'orbitz',
  'quest',
  'sadbois',
  'comeback',
  'laughing',
  'kings',
  'demons',
  'larmack',
  'soondubu',
  'waterloo',
  'alberta',
  'floppa',
  'ayumi',
  'zealots',
  'ignition',
  'meta',
  'sktt1',
  'bomba',
  'crayon',
  'munchers',
  'inters',
  'church',
  'taiga',
  'breezey',
  'birb',
  'pawns',

  '2st',
  'zzz',
  'kaz',
  'kfc',
  'jon',
  'twi',
  'pp7',
  '3zy',
  'oki',
  'abl',
  'dixon',
  'flair',
  'taco',
  'lazy',
  'corey',
  'teremina',
  'xdme',
  'g33ke',
  'lumost',
  'ginger',
  'yokie',
  'reznok',
  'daft',
  'loljss',
  'amerats',
  'khionae',
  'arcane',
  'chicken',
  'boo20k',
  'pr1me',
  'cast',
  'blufloo',
  'blaze',
  'trick',
  'fwop',
  'fwopdwop',
  'gravity',
  'khamet',
  'lockoff',
  'mira',
  'miragent',
  'slop',
  'lyarri',
  'kraked',
  'lexus',
  'goomy',
  'caleb',
  'double',
  'enoc',
  'gengur',
  'hook',
  'ignis',
  'j7ang',
  'leoleon',
  'kisu',
  'mrhudat',
  'mycup',
  'naka',
  'imsalis',
  'salis',
  'shiro',
  'sophia',
  'stepi',
  'streak',
  'temptr',
  'threezy',
  'venz',
  'appabug',
  'barcode',
  'bells',
  'kamilla',
  'walnut',
  'lemur',
  'floppyz',
  'remix',
  'snake',
  'soren',
  'xenith',
  'yeti',
  'bobadex',
  'borg',
  'cuberto',
  'hoishi',
  'kienoob',
  'kameron',
  'kong',
  'kaiwen',
  'matty',
  'numbered',
  'oldrey',
  'pengwy',
  'puffy',
  'puffyfps',
  's1lent',
  'snoball',
  'kaiser',
  'thejoker',
  'joker',
  'treddy',
  'train',
  'wallaby',
  'yeesh',
  'yuce',
  'yuume',
  'excellex',
  'kitsu',
  'obscure',
  'hotty',
  'grimella',
  'griffin',
  'mloom',
  'solazur',
  'overflow',
]
