import { VALID_GUESSES_3 } from './english/validGuesses_3'
import { VALID_GUESSES_4 } from './english/validGuesses_4'
import { VALID_GUESSES_5 } from './english/validGuesses_5'
import { VALID_GUESSES_6 } from './english/validGuesses_6'
import { VALID_GUESSES_7 } from './english/validGuesses_7'
import { VALID_GUESSES_8 } from './english/validGuesses_8'
import { VALID_GUESSES_9 } from './english/validGuesses_9'

export const VALID_GUESSES = [
  [],
  [],
  [],
  VALID_GUESSES_3,
  VALID_GUESSES_4,
  VALID_GUESSES_5,
  VALID_GUESSES_6,
  VALID_GUESSES_7,
  VALID_GUESSES_8,
  VALID_GUESSES_9,
]
