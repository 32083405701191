export const WORDS_ASIA = [
  'asswhy',
  'kitey',
  'liltjay',
  'plyasm',
  'hanabi',
  'gaagaa',
  'yesgot',
  'lazey',
  'whuh',
  'fmiz',
  'markers',
  'tsp',
  'brax',
  'draggun',
  'yogurt',
  'hbcat13',
  'oalei',
  'poxxy',
  'sena',
  'sharpe',
  'topmkayn',
  'flyerton',
  'kzb',
  'dorsche',
  'goatkun',
  'cocoya',
  'teioh',
  'rainb',
  'naber1us',
  'sheepr',
  'genkun',
]
