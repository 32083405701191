export const WORDS_DISCORD = [
  'dixon',
  'obi',
  'net',
  'twig',
  'kuyachi',
  'flair',
  'taco',
  'lazy',
  'corey',
  'teremina',
  'stinky',

  'sonii',
  'kelly',
  'noodle',
  'aesop',
  'maple',
  'lunchboy',
  'sotere',
  'teej',
  'wav3',
  'vienna',

  'kfc',
  'twi',
  'trpfy',
  'redsnow',
  'hbcat13',
  'tsp',
  'molle',
  'xdme',
  'gnomey',
  'sunfish',
  'sucy',
  'g33ke',
  'lumost',
  'ginger',
  'yokie',
  'reznok',
  'srskull',
  'lemur',
  'daft',
  'loljss',
  'amerats',
  'beepo',
  'khionae',
  'arcane',
  'chicken',
  'agon',
  'etir',
  'catboy',
  'boo20k',
  'blufloo',
  'blaze',
  'trick',
  'lexus',
  'ignis',
  'kisu',
  'naka',
  'shiro',
  'sophia',
  'streak',
  'temptr',
  'lemur',
  'walnut',
  'soren',
  'bobadex',
  'hoishi',
  'kong',
  'kaiwen',
  'oldrey',
  'kaiser',
  'yeesh',
  'mloom',
  'solazur',
  'overflow',

  'aws',
  'achi',
  'ratlas',
  'xdd',
  'yea',
  'bozo',
  'sheesh',
  'shrimply',
  'shrimple',
  'surely',
  'bedge',
  'sadge',
  'madge',
  'deadge',
  'comfy',
  'glow',
  'fight',
  'punch',
  'copium',
  'procure',
  'spinning',
  'hampter',
  'wiwi',
  'wiwis',
  'diff',
  'smurf',
  'smurfs',
  'fear',
  'korea',

  'aimipat', // emotes
  'juliglow',
  'juliez',
  'julisad',
  'asherpog',
  'lunanice',
]
