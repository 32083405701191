export var WORDS_DAILY = [
  'maple',
  'sadbois',
  'bells',
  'tsp',
  'lexus',
  'rookie',
  'orbs',
  'builtdiff',
  'goals',
  'buffs',
  'wallaby',
  'plat',
  'pingpong',
  'shrimply',
  'acro',
  'solazur',
  'kisu',
  'gnomey',
  'ron',
  'inters',
  'strikers',
  'comfy',
  'hampter',
  'nibiria',
  'asher',
  'stacks',
  'zentaro',
  'grimella',
  'lyarri',
  'power',
  'alex',
  'double',
  'kfc',
  'rune',
  'zzz',
  'creators',
  'pbwm',
  'bobadex',
  'kekle',
  'chally',
  'numbered',
  'oki',
  'chicken',
  'xenith',
  'stack',
  'agon',
  'elusive',
  'stepi',
  'loljss',
  'trpfy',
  'banish',
  'salis',
  'rock',
  'lemur',
  'narrows',
  'twig',
  'hoishi',
  'estelle',
  'cafe',
  'sandiego',
  'duboo',
  'kai',
  'blobbos',
  'vienna',
  'streets',
  'gloria',
  'hafu',
  'creation',
  'backroom',
  'spinning',
  'durable',
  'yuce',
  'flair',
  'healing',
  'lazy',
  'dan',
  'grilla',
  'frust',
  'immune',
  'kelly',
  'ratlas',
  'haste',
  'assists',
  'juli',
  'hook',
  'kamilla',
  'ctl',
  'surely',
  'enoc',
  'sucy',
  'gold',
  'platinum',
  'kong',
  'crayon',
  'ody',
  'sadge',
  'mloom',
  'shrimple',
  'gravity',
  'train',
  'thorns',
  'gengur',
  'overflow',
  'forward',
  'coc',
  'ignis',
  'pr1me',
  'xmetix',
  'swagger',
  'kings',
  'goomy',
  'teremina',
  'g33ke',
  'larmack',
  'nurse',
  'astrid',
  'arcane',
  'prime',
  'wav3',
  'juliette',
  'barcode',
  'atsuro',
  'eight',
  'ohio',
  'expanse',
  'kitsu',
  'boost',
  'leoleon',
  'puffy',
  'kaiwen',
  'twi',
  'kitsune',
  'srskull',
  'bronze',
  'church',
  'juliet',
  'caleb',
  'boo20k',
  'soren',
  'zealots',
  'bomba',
  'cbk',
  'remix',
  'kraked',
  'sotere',
  'stinger',
  'stagger',
  'xdme',
  'soondubu',
  'lumost',
  'borg',
  'rekkosed',
  'barrage',
  'kameron',
  'yokie',
  'flex',
  'smurfs',
  'venz',
  'dubu',
  'cast',
  '3zy',
  'laughing',
  'threezy',
  'obscure',
  'clash',
  'rollout',
  'molle',
  'kienoob',
  'mycup',
  'mrhudat',
  'yuume',
  's1lent',
  'flurry',
  'wall',
  'juno',
  'shieldz',
  'mystic',
  'miragent',
  'aimi',
  'imsalis',
  'kbot',
  'maximus',
  'buffing',
  'fwopdwop',
  'connax',
  'redsnow',
  'funnel',
  'diamond',
  'jon',
  'lunchboy',
  'inhouses',
  'pengwy',
  'shiro',
  'coyne',
  'bumpers',
  'oldrey',
  'lama',
  'temptr',
  'tsuneko',
  'khamet',
  'dixon',
  'overtime',
  'net',
  'lynx',
  'coredle',
  'odyssey',
  'amerats',
  'clones',
  'hotty',
  'streak',
  'adren',
  'sonii',
  'noodle',
  'floppa',
  'demons',
  'abl',
  'khionae',
  'large',
  'meta',
  'laggy',
  'venom',
  'arcadian',
  'griffin',
  'blaze',
  'pp7',
  'alts',
  'appabug',
  'inter',
  'saya',
  'drex',
  'floppyz',
  'balu',
  'orbitz',
  'catboy',
  'cuberto',
  'orbital',
  'atlas',
  'yeti',
  'naka',
  'comeback',
  'bluelock',
  'kills',
  'snoball',
  'lockoff',
  'bozo',
  'matty',
  'joker',
  'crabb',
  'blufloo',
  'core',
  'strsat',
  'teej',
  'madge',
  'sunfish',
  'alberta',
  'waterloo',
  'goalie',
  'sayachi',
  'snake',
  'tuesday',
  'octagon',
  'split',
  'piols',
  'aesop',
  'walnut',
  'kevfun',
  'taco',
  'origins',
  'xdd',
  'yeesh',
  'sophia',
  'omega',
  'ahten',
  'autumn',
  'obscura',
  'chicago',
  'crater',
  'etir',
  'silver',
  'munchers',
  'kaiser',
  'brickbat',
  'eject',
  'luna',
  'yea',
  'kuyachi',
  'david',
  'brawler',
  'wcw',
  'hbcat13',
  'wiwi',
  'quest',
  'procure',
  'vaughn',
  'ayumi',
  'startgg',
  'kamidere',
  'pawns',
  'sktt1',
  'drekar',
  'ability',
  'bedge',
  'nine',
  'costco',
  'obi',
  'corey',
  'slop',
  'kate',
  'treddy',
  'dash',
  'whammy',
  'achi',
  'reznok',
  'j7ang',
  'trick',
  'excellex',
  'ignition',
  'copium',
]
